<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="会员管理" /> -->
        <SearchForm class="dis-flex flex-x-start flex-y-start">
            <el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
                <el-form-item>
                    <el-input v-model.trim="searchForm.userName" placeholder="请输入姓名" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryPCDcOrderPay">搜 索</el-button>
                </el-form-item>
            </el-form>
            <el-button type="success" @click="add" class="turnBule">临时人员报餐</el-button>
            <el-button type="success" @click="partAdd" class="turnBule">部门批量报餐</el-button>
        </SearchForm>


        <TableContainer title="">
            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="userId" label="id" min-width="60" />
                <el-table-column prop="dayTime" label="日期" min-width="60" />
                <el-table-column prop="roleName" label="部门" min-width="60" />
                <el-table-column prop="fullName" label="姓名" min-width="60" />
                <el-table-column prop="type" label="类型" min-width="60" />
                <el-table-column prop="overtimeName" label="是否加班" min-width="100" />
            <el-table-column prop="price" label="价格" min-width="60" />
                <el-table-column prop="orderNo" label="订单号" min-width="100" />
                <el-table-column prop="payStateName" label="支付状态" min-width="100" />
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryPCDcOrderPay" />
        </TableContainer>
        <!-- 临时人员报餐s -->
        <GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm()" @btnClose="resetThemeForm()">
            <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
                <el-form-item label="类型" label-width="100px">
                    <el-select v-model="dishName" placeholder="请选择报餐类型" @change="changeType">
                        <el-option v-for="(item, index) in dishList" :key="index" :label="item.name" :value="item.name" />
                    </el-select>
                </el-form-item>
                <el-form-item label="日期" label-width="100px">
                    <el-date-picker v-model.trim="dayTime" type="date" placeholder="选择筛选日期" value-format="yyyy-MM-dd"
                        @change="selectTime" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="人数" label-width="100px">
                    <div style="width: 220px;">
                        <el-input v-model.trim="people" placeholder="请输入报餐人数" type="number" />
                    </div>

                </el-form-item>
            </el-form>
        </GDialog>
        <!-- 临时人员报餐e -->

        <!-- 部门报餐s -->
        <GDialog :dialog.sync="themeDialogs" @btnSure="submitThemeForms()" @btnClose="resetThemeForm()">
            <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
                <el-form-item label="类型" label-width="100px">
                    <el-select v-model="dishName" placeholder="请选择报餐类型" @change="changeType">
                        <el-option v-for="(item, index) in dishList" :key="index" :label="item.name" :value="item.name" />
                    </el-select>
                </el-form-item>
                <el-form-item label="日期" label-width="100px">
                    <el-date-picker v-model.trim="dayTime" type="date" placeholder="选择筛选日期" value-format="yyyy-MM-dd"
                        @change="selectTime" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="部门">
                    <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                        <el-select v-model="partName" placeholder="请选择部门" @change="(val) => partChangeType(val)" filterable
                            allow-create default-first-option>
                            <el-option v-for="(item, index) in typeList" :key="index" :label="item.organizationName"
                                :value="item.id" />
                        </el-select>
                    </el-form>
                </el-form-item>
                <el-form-item label="部门人员" v-if="cities.length!==0">
                  
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="(item,index) in cities" :label="item.id" :key="item.id">{{ item.fullName }}</el-checkbox>
                    </el-checkbox-group>

                </el-form-item>


                <!-- <el-form-item label="人数" label-width="100px">
                    <div style="width: 220px;">
                        <el-input v-model.trim="people" placeholder="请输入报餐人数" type="number" />
                    </div>

                </el-form-item> -->
            </el-form>
        </GDialog>
        <!-- 部门报餐e -->







    </section>
</template>

<script>
import {
    tempInsertBatch,
    queryPCDcOrderPay,
    selectDcDept,
    findDeptUser,
    updateDeptDcUserMeals,
} from '@api/bx/api_contentManagement';
export default {
    data() {
        return {
            themeDialog: {
                title: '临时人员报餐',
                visible: false
            },
            themeDialogs: {
                title: '部门批量报餐',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10,
                userName: ''
            },
            total: 0,
            tableData: [],
            dishList: [
                { name: '中餐' },
                { name: '晚餐' },
            ],
            dishName: '中餐',
            dicCode: null,
            addCode: null,
            addItem: {},
            dicName: '',
            isEditor: false,
            editorRow: {},
            dayTime: '',
            people: 0,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - 3600 * 1000 * 24
                },
            },
            typeList: [],
            partName: '',
            checkAll: false,
            checkedCities: [],
            cities: [],
            isIndeterminate: true
        }
    },
    created() {
        this.queryPCDcOrderPay()


    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },
   
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },

        changeType(val) {
            this.dishName = val

        },
        //部门
        partChangeType(val) {
            console.log(val)
            this.partName = val
            this.findDeptUser()
        },
        partAdd() {
            this.checkedCities=[]
            this.typeList=[]
            this.partName=''
            this.cities=[]
            this.dayTime=''
            this.selectDcDept()
            this.themeDialogs.visible = true
        },
        //新增
        add() {
            this.dayTime=''
            this.people=0
            this.themeDialog.visible = true
        },
        submitThemeForms(){
            console.log(this.checkedCities)
            if (this.dayTime == '') {
                this.$alert('请选择部门报餐的时间', '提示', {
                    dangerouslyUseHTMLString: true
                });
                return
            }
            if (this.partName == '') {
                this.$alert('请选择报餐部门', '提示', {
                    dangerouslyUseHTMLString: true
                });
                return
            }
            if (this.checkedCities.length == 0) {
                this.$alert('请选择部门报餐人员', '提示', {
                    dangerouslyUseHTMLString: true
                });
                return
            }
            updateDeptDcUserMeals({
                type: this.dishName,
                dayTime: this.dayTime,
                ids: this.checkedCities.join(','),
            }).then(res => {
                this.dishName=''
                this.dayTime=''
                this.themeDialogs.visible = false
                this.queryPCDcOrderPay()
            })
        },
        //确定
        submitThemeForm() {
            if (this.dayTime == '') {
                this.$alert('请选择临时报餐的时间', '提示', {
                    dangerouslyUseHTMLString: true
                });
                return
            }
            if (this.people == 0) {
                this.$alert('请输入临时报餐的人数', '提示', {
                    dangerouslyUseHTMLString: true
                });
                return
            }
            tempInsertBatch({
                type: this.dishName,
                dayTime: this.dayTime,
                man: this.people,
            }).then(res => {
                this.dishName=''
                this.dayTime=''
                this.people=0
                this.themeDialog.visible = false
                this.queryPCDcOrderPay()
            })
        },
        //取消按钮
        resetThemeForm() {
            this.themeDialog.visible = false
            this.themeDialogs.visible = false
        },
        //列表时间筛选
        selectTime(val) {
            this.dayTime = val
        },

        // 会员管理列表
        queryPCDcOrderPay() {
            queryPCDcOrderPay(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total

            })
        },

        // 部门列表
        selectDcDept() {
            selectDcDept({}).then(res => {
                this.typeList = res
            })
        },
        findDeptUser(){
            findDeptUser({estimatedAttributionId:this.partName}).then(res => {
                this.cities = res
            })
        },

        //表格删除
        delteFormClick(row) {
            this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // cfdelCosmeticsMaterial({ batchMonomer: row.batchMonomer }).then(res => {
                // 	this.cfqueryCosmeticsMaterial()
                // })
            })

        }


    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    // color: #fff;
    color: #333;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

